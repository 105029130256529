import { addDays } from "date-fns";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import imageTwo from "../../assets/img/abuja.webp";
import appline from "../../assets/img/appline.svg";
import appleBtn from "../../assets/img/appstore.webp";
import cards from "../../assets/img/card.webp";
import imageFive from "../../assets/img/edo.webp";
import googleBtn from "../../assets/img/google.webp";
import imageOne from "../../assets/img/lagos-ajah.webp";
import ep from "../../assets/img/man.webp";
// import newTag from "../../assets/img/new.png";
import phoneBg from "../../assets/img/phonebg.webp";
import newTag from "../../assets/img/new-flight.png";
// import PopupImage from "../../assets/img/popup-img.jpg";
import updateImage from "../../assets/img/importantupdate.png";
// import updateImage from "../../assets/img/update.webp";
import noticeImage from "../../assets/img/notice.jpeg";
// import PalliativeImage from "../../assets/img/cashback.webp";
import imageFour from "../../assets/img/portharcout-1.webp";
import imageThree from "../../assets/img/uselu.webp";
import Button from "../../components/Button";
import Select from "../../components/Dropdown/index";
import Footer from "../../components/Footer";
import InputField from "../../components/InputField/index";
import Loader from "../../components/Loader";
import Modal from "../../components/Modal";
import ModalComponentPopup from "../../components/ModalComponentPopup";
import Navbar from "../../components/NavBar";
import configs from "../../configs";
import apiroutes from "../../services/apiroutes";
import { request, requestSetting } from "../../services/apiservice";
import { requestWallet } from "../../services/apiserviceWallet";
import { getAuth, getUser, setAuth, setAuthWallet } from "../../services/auth";
import BookSeat from "./BookSeat";
import BookStatus from "./BookingStatus";
import HireBus from "./HireBus";
import "./index.css";
import ModalComponentPopupTravel from "../../components/ModalPopupTravel";
import { decrypt, encrypt } from "../../components/util";

export const HomeComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [popularData, setPopularData] = useState([]);
  const [routeLoading, setRouteLoading] = useState(true);
  const [bookRouteNowVisible, setBookRouteNowVisible] = useState(false);
  const [popularRouteDestination, setPopularRouteDestination] = useState("");
  const [popularRouteDeparture, setPopularRouteDeparture] = useState("");
  const [popularRouteDepartureId, setPopularRouteDepartureId] = useState("");
  const [popularRouteDestinationId, setPopularRouteDestinationId] =
    useState("");
  const [departureTerminal, setDepartureTerminal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [adult, setAdult] = useState("1");
  const token = getAuth();
  const [toggleState, setToggleState] = useState(1);
  const [modalVisiblePopup, setVisiblePopup] = useState(false);
  const [modalVisibleNoticePopup, setModalVisibleNoticePopup] = useState(false);
  const [modalVisibleTravelstart, setVisibleTravelstart] = useState(false);



  const toggleTab = (index) => {
    setToggleState(index);
  };

  const history = useHistory();
  const userInfo = getUser();
  useEffect(() => {
    if (userInfo === null) {
      history.push("/");
      getToken();
    } else {
      history.push("/dashboard");
    }
    getWalletToken();
    popularRoute();
    localStorage.removeItem("hireEmailAmount");
    localStorage.removeItem("transEmailAmount");
    localStorage.removeItem("estimatedTravelDistance");
    localStorage.removeItem("allHireDetails");
    localStorage.removeItem("selectedHireBuses");
    localStorage.removeItem("busNameQantity");
    localStorage.removeItem("returnPickupDate");
    localStorage.removeItem("userSelect");
    localStorage.removeItem("allTripDetails");
    localStorage.removeItem("selectedBusData");
    localStorage.removeItem("selectedSeats");
    localStorage.removeItem("selectedSeatsReturn");
    localStorage.removeItem("selectedReturnBusData");
    localStorage.removeItem("wovenAccountDetails");
    localStorage.removeItem("walletUserDetails");
    localStorage.removeItem("wovenWalletAccountDetails");
    localStorage.removeItem("walletPin");
    localStorage.removeItem("adults");
    localStorage.removeItem("adultsRound");
    localStorage.removeItem("retainBus");
    localStorage.removeItem("retainBusReturn");
    localStorage.removeItem("forgotEmail");
    localStorage.removeItem("distanceKmReturn");
    localStorage.removeItem("distanceKm");
    localStorage.removeItem("returnHireDate");
    localStorage.removeItem("azaPayload");
    localStorage.removeItem("allBookingStatus");
    // localStorage.removeItem("walletState");
    // localStorage.removeItem("lastModalShownTime");
    // setVisiblePopup(true);
    checkAndShowModal();
    hidePopupModalAfterDate();
  }, []);

  const checkAndShowModal = () => {
    // Get the last time the modal was shown from localStorage
    const lastModalShownTime = localStorage.getItem("lastModalShownTime");

    // If lastModalShownTime is not set, or if 2 weeks have passed
    if (
      !lastModalShownTime ||
      Date.now() - parseInt(lastModalShownTime) > 2 * 7 * 24 * 60 * 60 * 1000
    ) {
      // Show the modal
      setVisiblePopup(true);

      // Store the current time as the last time the modal was shown
      localStorage.setItem("lastModalShownTime", Date.now());
    }
  };

  const hidePopupModalAfterDate = () => {
    // Set the target date (May 4th, 2024)
    const targetDate = new Date("2024-05-04");

    // Get the current date
    const currentDate = new Date();

    // Compare the current date with the target date
    if (currentDate > targetDate) {
      // If the current date is after May 4th, 2024, hide the modal
      setModalVisibleNoticePopup(false);
      return;
    }

    setModalVisibleNoticePopup(true);
  };

  const toggleModalTravelstartClose = () => {
    setVisibleTravelstart(false);
    toggleTab(1);
    // console.log(window.innerWidth, "WINDOW WIDTH");
  };

  const getToken = async () => {
    // const formEncoded = new URLSearchParams({
    //   username: config.USERNAME,
    //   password: config.PASSWORD,
    //   client_id: config.CLIENT_ID,
    //   grant_type: config.GRANT_TYPE,
    //   client_secret: config.CLIENT_SECRET,
    // });

    const loginDetails = {
      userName: configs.USERNAME,
      password: configs.PASSWORD,
      deviceToken: "string",
      countryId: 1,
      clientId: configs.USERNAME,
    };

    const key = configs.ENCRYPT_KEY;
    const iv = configs.ENCRYPT_IV;


    const { ciphertext } = await encrypt(loginDetails, key, iv);

    const loginPayload = {credentials: ciphertext}

    request(apiroutes.GetToken(), "post", loginPayload)
      .then(async (res) => {
        const decryptedData = await decrypt( res.data.data, key, iv);

        setAuth(decryptedData?.Data?.JwtToken);
        getDepartureTerminal();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const getDepartureTerminal = () => {
    requestSetting(apiroutes.DepartureTerminals(), "get", null, getAuth())
      .then((res) => {
        setDepartureTerminal(res.data?.data?.userData);
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          getRefreshToken();
        }
      });
  };

  const getRefreshToken = () => {
    const payload = {
        token: getAuth(),
        refreshToken: "string"
    }
    request(apiroutes.GetNewToken(), "post", payload, null)
      .then((res) => {
        setAuth(res?.data?.data?.token);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((err) => {
        console.log(err.response);
        alert("Session Expired, Please log in");
        history.push("/login");
      });
  };

  const getWalletToken = () => {
    const formEncoded = new URLSearchParams({
      client_id: "mobility",
      grant_type: "client_credentials",
      client_secret: "158ee0ea-c52b-4735-9145-110cd00f37e1",
    });

    requestWallet(apiroutes.GetWalletToken(), "post", formEncoded)
      .then((res) => {
        const data = {
          access_token: res.data.access_token,
          expires_in: res.data.expires_in,
          token_type: res.data.token_type,
        };
        setAuthWallet(data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const adults = [];
  for (let i = 1; i <= 13; i++) {
    adults.push(i);
  }
  const adultOptions = adults.map((x) => ({ label: x, value: x }));

  const colorStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: () => {
      const color = "black";

      return { color };
    },
  };

  const handleOneWay = (e) => {
    e.preventDefault();
    const newStartDate = new Date(startDate);
    let date = JSON.stringify(newStartDate);
    date = date.slice(1, 11);
    setLoading(true);
    const bookingData = {
      DepartureTerminalId: popularRouteDepartureId,
      DestinationTerminalId: popularRouteDestinationId,
      DepartureDate: date,
      NumberOfAdults: adult,
      TripType: "0",
      BookingType: "0",
      NumberOfChildren: "0",
    };

    const userSelect = {
      noOfAdult: adult,
      date: startDate,
      departureName: popularRouteDeparture.replace("=>", "-"),
      arrivalName: popularRouteDestination.replace("=>", "-"),
    };

    localStorage.setItem("userSelect", JSON.stringify(userSelect));

    request(apiroutes.BookingSearch(), "post", bookingData, token)
      .then((res) => {
        setLoading(false);
        if (res.data.Object === null) {
          // alert("Error validating your request, Please try again!");
          Swal.fire({
            showConfirmButton: false,
            timer: 4000,
            text: `Error validating your request, Please try again!`,
            icon: "error",
          });
          history.push("/");
          window.location.reload();
        } else {
          localStorage.setItem(
            "allTripDetails",
            JSON.stringify(res.data.Object)
          );
          history.push("/select-bus");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const popularRoute = () => {
    requestSetting(apiroutes.GetpopularRoute(), "get", null, token)
      .then((res) => {
        const data = res.data.data;
        setPopularData(res.data.data);
        setRouteLoading(false);
        // setAuthWallet(data);
      })
      .catch((err) => {
        // alert(err);
        console.log(err);
      });
  };

  const bookRouteNow = (item) => {
    setBookRouteNowVisible(true);
    setPopularRouteDestination(item?.destinationTerminalName);
    setPopularRouteDeparture(item?.departureTerminalName);
    setPopularRouteDepartureId(item?.departureTerminalId);
    setPopularRouteDestinationId(item?.destinationTerminalId);
  };

  const toggleModalClose = () => {
    setBookRouteNowVisible(false);
    // window.location.reload();
  };

  const toggleModalPopupClose = () => {
    setVisiblePopup(false);
    toggleTab(1);
    // console.log(window.innerWidth, "WINDOW WIDTH");
  };
  const toggleModalNoticePopupClose = () => {
    setModalVisibleNoticePopup(false);
  };

  const width = 500;
  const width2 = 1200;
  const height = 580;
  const height2 = 500;
  const height3 = 650;
  // const modalTitle = `Book a Bus from ${popularRouteDeparture} to ${popularRouteDestination}`;
  const modalTitle = `Book a Bus from ${popularRouteDeparture?.replace(
    "=>",
    "-"
  )} to ${popularRouteDestination?.replace("=>", "-")}`;
  const modalPar = "Please enter details below to proceed.";
  // const modalPar2 = "Book A Flight.";
  const modalBody = (
    <div>
      <label htmlFor="departure" className="label-auth">
        Travelling From
      </label>
      <InputField
        type="text"
        // placeholder="Verification code"
        value={popularRouteDeparture}
        onChangeMethod={(e) => setPopularRouteDeparture(e.target.value)}
        readonly={true}
      />
      <br />
      <br />
      <label htmlFor="arrival">Travelling To</label>
      <InputField
        type="text"
        // placeholder="Demmah16@gmail.com"
        value={popularRouteDestination}
        onChangeMethod={(e) => setPopularRouteDestination(e.target.value)}
        readonly={true}
      />
      <br />
      <br />
      <div className="row row-grid">
        <div className="col-md-6">
          <label className="label-auth">Departure Date</label>
          <DatePicker
            placeholderText="Select Date"
            selected={startDate}
            minDate={addDays(new Date(), 1)}
            maxDate={addDays(new Date(), 14)}
            onChange={(date) => setStartDate(date)}
            className="black"
            // readOnly={true}
          />
        </div>
        <div className="col-md-6">
          <label className="label-auth">Adults</label>
          <Select
            options={adultOptions}
            handleChange={(e) => setAdult(e.value)}
            defaultValue={{ label: "1", value: 1 }}
            value={adult}
            styles={colorStyles}
          />
        </div>
      </div>
      <br />
      <br />
      {/* <Button
        text="Proceed"
        handleButtonClick={verifyCode}
        disabled={!verificationCode}
        type="button"
      /> */}
      <div className="row">
        <div className="col-md-12">
          <Button
            text={
              loading ? (
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "200px",
                    transform: "translateX(-10px)",
                  }}
                >
                  <Loader dark={false} />{" "}
                  <p style={{ display: "inline-block" }}>Looking for Buses</p>
                </div>
              ) : (
                "Proceed"
              )
            }
            handleButtonClick={handleOneWay}
            type="button"
            btnstyle={{
              background: "#E21D00",
              color: "white",
              margin: "20px 0px",
              // fontFamily: "Euclid Circular B",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
            }}
            disabled={!(startDate && adult)}
          />
        </div>
      </div>
    </div>
  );

  const modalBodyPopup = (
    <div
      style={{
        width: "100%",
        height: "460px",
        overflow: "hidden",
        objectFit: "contain",
        position: "relative",
      }}
    >
      {/* <span
        style={{
          position: "absolute",
          background: "red",
          height: "30px",
          width: "30px",
          top: "-25px",
          right: "-20px",
          xIndex: "20",
          color: "white",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={toggleModalPopupClose}
      >
        X
      </span> */}
      <img
        style={{ width: "100%", height: "100%" }}
        src={updateImage}
        // src={PalliativeImage}
        // src={PopupImage}
        alt="Update popup"
      />
    </div>
  );

  const modalBodyTravelstart = (
    <div
      style={{
        width: "100%",
        height: "580px",
        objectFit: "contain",
        position: "relative",
      }}
    >
      {/* <span
        style={{
          position: "absolute",
          background: "red",
          height: "30px",
          width: "30px",
          top: "-25px",
          right: "-20px",
          xIndex: "20",
          color: "white",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={toggleModalPopupClose}
      >
        X
      </span> */}
      <iframe
        title="A custom made iframe"
        src="https://gigm.otaas.travel/?cpySource=gigmng&affId=gigmng&affCampaign=gigmng&utm_medium=partner"
        width={
          window.innerWidth >= 900
            ? "1150"
            : window.innerWidth >= 600
            ? "900"
            : "350"
        }
        height={550}
        frameBorder="0"
        // sandbox="allow-scripts allow-modal"
        // loading="eager"
      ></iframe>
      {/* </a> */}
    </div>
  );

  const modalBodyNoticePopup = (
    <div
      style={{
        width: "100%",
        height: "460px",
        overflow: "hidden",
        objectFit: "contain",
        position: "relative",
      }}
    >
      {/* <span
        style={{
          position: "absolute",
          background: "red",
          height: "30px",
          width: "30px",
          top: "-25px",
          right: "-20px",
          xIndex: "20",
          color: "white",
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={toggleModalPopupClose}
      >
        X
      </span> */}
      <img
        style={{ width: "100%", height: "100%" }}
        src={noticeImage}
        // src={PalliativeImage}
        // src={PopupImage}
        alt="Notice popup"
      />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 490,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // dots: true,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <div id="top" style={{ width: "100vw", overflowX: "hidden" }}>
      <Modal
        width={width}
        height={height}
        visible={bookRouteNowVisible}
        title={modalTitle}
        paragraph={modalPar}
        body={modalBody}
        handleClose={toggleModalClose}
      />
      <ModalComponentPopup
        width={width}
        height={height2}
        visible={modalVisiblePopup}
        body={modalBodyPopup}
        handleClose={toggleModalPopupClose}
      />
      <ModalComponentPopup
        width={width}
        height={height2}
        visible={modalVisibleNoticePopup}
        body={modalBodyNoticePopup}
        handleClose={toggleModalNoticePopupClose}
      />
      <ModalComponentPopupTravel
        width={width2}
        height={height3}
        visible={modalVisibleTravelstart}
        // title={modalPar2}
        body={modalBodyTravelstart}
        handleClose={toggleModalTravelstartClose}
      />
      <Navbar />
      <section className="hero-bg">
        <div className="container">
          <div className="hero-flex">
            <div className="left-card align-self-center">
              <h1>The modern way to commute across cities</h1>
              <br />
              <p>
                GIGM is an African technology powered company, providing
                seamless mobility services to commuters across Africa
              </p>
            </div>

            <div className="right-card">
              <section id="open-positions">
                <div className="positions">
                  <div className="position-filters">
                    <label
                      className={
                        toggleState === 1
                          ? "tabb-label active-tabs"
                          : "tabb-label"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      Book a seat
                    </label>
                    <label
                      className={
                        toggleState === 2
                          ? "tabb-label active-tabs"
                          : "tabb-label"
                      }
                      // onClick={() => {
                      //   Swal.fire({
                      //     showConfirmButton: false,
                      //     timer: 4000,
                      //     text: `This feature is unavailable at the moment, Please try again later!`,
                      //     icon: "error",
                      //   });
                      //   toggleTab(1);
                      // }}
                      onClick={() => toggleTab(2)}
                    >
                      Hire a bus
                    </label>
                    <label
                      className={
                        toggleState === 3
                          ? "tabb-label active-tabs"
                          : "tabb-label"
                      }
                      onClick={() => toggleTab(3)}
                    >
                      Booking status
                    </label>
                    <label
                      className={
                        toggleState === 4
                          ? "tabb-label active-tabs blink_me"
                          : "tabb-label blink_me"
                      }
                      onClick={() => {
                        toggleTab(4);
                        setVisibleTravelstart(true);
                        // alert("Flight feature coming soon...");
                      }}
                      style={{ position: "relative", color: "white" }}
                    >
                      <img
                        src={newTag}
                        alt="nw-tag"
                        width="35"
                        height="35"
                        // className="download-btn-btn"
                        loading="lazy"
                        style={{
                          position: "absolute",
                          top: "-20px",
                          left: "30%",
                        }}
                      />
                      Book a flight
                    </label>
                  </div>

                  <div
                    className={
                      toggleState === 1
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post">
                    <BookSeat  departureTerminal={departureTerminal}/>
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 2
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post">
                      <HireBus />
                    </div>
                  </div>

                  <div
                    className={
                      toggleState === 3
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post">
                      <BookStatus />
                    </div>
                    <div
                      className={
                        toggleState === 4
                          ? "position-group  active-content"
                          : "position-group"
                      }
                    >
                      <div className="position-post">
                        {/* <iframe                         title="A custom made iframe"
 src="https://www.youtube.com/embed/uXWycyeTeCs" width={1000} height={500} sandbox='allow-scripts allow-modal' loading='eager'></iframe> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 4
                        ? "position-group  active-content"
                        : "position-group"
                    }
                  >
                    <div className="position-post"></div>
                  </div>
                  {/* </div> */}
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <section className="hot-trips pt-5 pb-5">
        <div className="container">
          <div className="row row-grid">
            <div className="col-md-5 align-self-center">
              <div className="prices-container">
                <h1>Best Trip Prices</h1>
                <p>Bringing you more routes at the best fares.</p>
              </div>
            </div>
            <div className="popular-route col-md-7">
              <div>
                {routeLoading ? (
                  <div className="text-center pt-3">
                    <div className="lds-default">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                ) : (
                  <Slider {...settings}>
                   
                    {popularData?.map((item, index) => (
                      <div key={index}>
                        <div className="slide-image">
                          {/* <img src={imageOne} alt="slider" /> */}
                          {index === 0 ? (
                            <img src={imageThree} alt="slider" loading="lazy" />
                          ) : index === 1 ? (
                            <img src={imageFive} alt="slider" loading="lazy" />
                          ) : index === 2 ? (
                            <img src={imageTwo} alt="slider" loading="lazy" />
                          ) : index === 3 ? (
                            <img src={imageFour} alt="slider" loading="lazy" />
                          ) : index === 4 ? (
                            <img src={imageOne} alt="slider" loading="lazy" />
                          ) : (
                            "slide-image"
                          )}
                          <div className="hold-items"></div>
                          <div className="centered-book">
                            <h2>{item.routeName.replace("==>", "to")}</h2>
                            <h5 className="pb-2" style={{ color: "red" }}>
                              {/* <b>&#35;{item.DispatchFee + item.CaptainFee}</b> */}
                            </h5>
                            <Button
                              text="Book Now"
                              handleButtonClick={() => bookRouteNow(item)}
                              type="button"
                              btnstyle={{
                                background: "#56CCF2",
                                color: "white",
                                width: "65%",
                                margin: "0 auto",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="app-section">
        <div className="app-section-text">
          {/* <span>Introducing</span> */}
          <div className="">
            <h1>
              Enjoy the{" "}
              <span style={{ color: "red", background: "none" }}>
                GIGM Appvantage
              </span>
            </h1>
            <h1>Move Freely, Do Easily</h1>
            <p>
              It is all you need in one travel app. Book bus tickets, hire a
              vehicle and pay bills.
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://onelink.to/j7k4zu"
            >
              {/* <Link to="https://play.google.com/store/apps/details?id=com.gigm"> */}{" "}
              <img
                src={googleBtn}
                alt=""
                className="download-btn-btn"
                loading="lazy"
              />
            </a>
            &nbsp;
            <a
              target="_blank"
              rel="noreferrer"
              href="https://onelink.to/j7k4zu"
            >
              {/* <Link to="https://apps.apple.com/ng/app/gig-mobility/id1154045195"> */}
              <img
                src={appleBtn}
                alt=""
                className="download-btn-btn"
                loading="lazy"
              />
            </a>
          </div>
        </div>
        <div className="app-section-image">
          <img
            src={phoneBg}
            alt=""
            className=""
            loading="lazy"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-md-7">
          <img src={appline} alt="" className="appline" loading="lazy" />
        </div>
        {/* </div> */}
        {/* </div> */}
      </section>

      <section className="wallet-section">
        <div className="container">
          <div className="row row-grid">
            <div className="col-md-6 align-self-center">
              <h1>The lightest digital wallet you will ever own</h1>
              <br />
              <p>
                The GIGM digital wallet is a seamless way to pay for
                transactions within our ecosystem. Purchase bus tickets,
                airtime, data and pay for utility bills using the digital wallet
                available only on the mobile app.
              </p>
            </div>
            <div className="col-md-6">
              <img src={cards} alt="" className="img-fluid" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      <section className="ep-section">
        <div className="container">
          <div className="row row-grid">
            <div className="col-md-6">
              <div className="ep-apply">
                <h1>We do the heavy lifting </h1>
                <p>Earn easy with GIGM</p>

                <p>Become an enterprise partner today!</p>

                <div className="ep-btn">
                  <Button
                    text="Start Earning"
                    handleButtonClick={() => {
                      history.push("/enterprise");
                    }}
                    type="button"
                    btnstyle={{
                      background: "#E21D00",
                      color: "white",
                      margin: "20px 0",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 align-self-center">
              <img src={ep} alt="" className="ep-apply-image" loading="lazy" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default HomeComponent;
