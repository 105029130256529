import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../../components/Footer";
import Navbar from "../../../components/NavBar";
import MobileNavBar from "../MobileNavbar/index";
import SideBar from "../SideBar/index";
import "./help.css";

const HelpComponent = () => {
  
    return (
      <div>
        <Navbar/>
      <div className="container">
        <div className="dashboardArea">
          <SideBar />
          <div className="contentarea">
            <MobileNavBar />
            <div className="help-support">
                <h3>Help &amp; Support</h3>
            </div>

            <div>
                <div className="helpholder">
                <div className="row row-grid">
                   <div className="col">
                       <div className="helpholder1">
                           <p>Customer Care Contact</p>
                           <h3> <a href="tel:08139851110">08139851110, <a href="mailto:">contact@gigm.com</a></a></h3>
                       </div>
                   </div>
                </div>

                <div className="row row-grid">
                   <div className="col">
                       <div className="helpholder1">
                           <p>Privacy and Policy</p>
                           <h3>Read through our Privacy Policy</h3>
                           <Link to="/Privacy-Policy"><i className="fa fa-arrow-right"></i></Link>
                           
                       </div>
                   </div>
                </div>

                <div className="row row-grid">
                   <div className="col">
                       <div className="helpholder1">
                           <p>Terms &amp; Conditions</p>
                           <h3>Read through our Terms &amp; Conditions</h3>
                           <Link to="/terms"><i className="fa fa-arrow-right"></i></Link>
                       </div>
                   </div>
                </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
      </div>
    );
  };
  
  export default HelpComponent;
  