export const termsData = [
    {
        id:1,
        title: 'Check In',
        content: `Check-in closes 30 minutes before the estimated time of departure. We recommend that guests honour our check-in policy, as departure time will not be extended. Guests who come in after check-in closes will be rescheduled for free however, this is subject to vehicle and seat availability.`
      },
      {
        id:2,
        title: 'Booking and Reservation',
        content: `Bookings and reservations made in advance are subject to availability of vehicles. In the event that we are unable to fulfill your reservation, affected guest(s) will be rescheduled to another departure time at no extra cost.`
      },
      {
        id:3,
        title: 'Luggage Allowance',
        content: `All guests are entitled to one medium sized luggage (30”x22”x15) per ticket. For comfort and safety purposes, we are unable to accommodate any luggage with dimensions exceeding this standard. In cases where specific guest(s) luggage exceeds this requirement, affected guests will be asked to either send the excess luggage via our sister company, GIG Logistics or purchase an extra seat to accommodate it.\nWe recommend the use of flexible traveling boxes as plastic boxes are not acceptable on our buses. This is because these boxes are fragile and not pliable enough which poses a challenge during luggage loading activities.\nOwing to the embargo placed by the Nigeria Customs on the importation of some food commodities, kindly note that we do not carry bag(s) of rice on board our vehicles. Fragile items such as electronics, laptops and other valuable items are not to be placed in the trunk of our vehicles but carried as hand luggage.\nPlease note that hazardous materials such as but not limited to - gas cylinders (empty or with content), generators, building materials, fuel in gallons, agrochemicals, herbicides, pesticides; etc. are not permissible on our vehicles but can be contracted to GIG Logistics for shipping.\nGuests are also advised to monitor their luggage at all times till their final destination.`
      },
      {
        id:4,
        title: 'Pick-up Service',
        content: `Our pick-up service is a safe point to point bus service for light travellers. Please note that a no-show without prior reschedule would render ticket void. Guests with luggage exceeding a small box (30”x22”x15) will be required to travel from the terminal.`
      },
      {
        id:5,
        title: 'Refund Policy',
        content: `GIGM has a no refund policy for all tickets duly purchased from any of her sales channels.`
      },
      {
        id:6,
        title: 'Managing Reservations (Rescheduling and Rerouting)',
        content: `Guests with unused tickets are advised to utilize as soon as possible as ticket validity is restricted to one month (30 days) from date of purchase, after which the ticket becomes invalid. To utilize such tickets within the validity period, affected guests will have their tickets rescheduled/rerouted via the following channels: Our Contact Centre by sending email to contact@gigm.com with new itinerary before proposed travel date, the GIGM WhatsApp Bot or through the mobile app.\nNote that all reschedules/ re-routing requests are subject to vehicle and seat availability on desired route.\nIn the event that the fare of the new trip is higher than the old trip, the guest will also be required to pay the differential for reschedule or re-routing request.`
      },
      {
        id:7,
        title: 'Debits without value on Online Transactions',
        content: `Guests are advised to contact their banks should they require a refund due to double debits, debits on card transactions or USSD payments without value using our online booking platform. Refund request originating from bank transfers can be processed by sending an email to contact@gigm.com. Upon confirmation on receipt of funds, the account number provided by the guest will be credit with refund value within seven (7) to ten (10) working days.`
      },
      {
        id:8,
        title: 'Price Changes',
        content: `The company reserves the right to review and modify its prices as prevalent economic situation warrants, without prior notice to guests.        `
      },
      {
        id:9,
        title: 'Child Fare Rates',
        content: `We care about the safety and comfort of children travelling with us and have provided 50% discounts on tickets purchased via our app for children. However, please note the following:\nThe Child fare rate only applies to children between the ages of 2-10.\nChildren within this range cannot travel unaccompanied by an adult.\nChild fare rates can only be assessable upon the presentation of a valid means of identification for the child (ren) on the planned day of travel. Failure of which will result in discount cancellation.\nAn adult is only allowed a maximum of three child fare rates per booking.`
      },
      {
        id:10,
        title: 'Carriage of Unaccompanied Children',
        content: `GIGM can only provide transportation for unaccompanied children between the ages of 10 to 15 on the condition that the parent/guardian of the minor completes and submits our Unaccompanied Minor Form before the commencement of trip. The form is obtainable from any of our terminals nation-wide.`
      },
      {
        id:11,
        title: 'Animals and Pets Onboard',
        content: `For health and safety reasons, GIGM has a no pet on board policy. Please note that boarding our buses with pets can lead to ticket cancellation.`
      },
      {
        id:12,
        title: 'Smoking Policy',
        content: `Smoking while in our terminal or on board our vehicle is prohibited.`
      },
      {
        id:13,
        title: 'Sale of Tickets',
        content: `GIGM bus tickets are not transferable and cannot be resold to anyone else.`
      },
      {
        id:14,
        title: 'Departure Time',
        content: `While we will do all within our control to ensure we comply with communicated departure times, in cases where unforeseen circumstances occur, please note that we are at liberty to alter the departure time without prior notice or compensation. This may have an effect on seat selection and bus type.`
      },
      {
        id:15,
        title: 'Ticket Validity',
        content: `Unused tickets are valid for a period of one month (30 days) from the date of purchase. All unused tickets will become invalid once the validity period is exceeded.`
      },
      {
        id:16,
        title: 'Lost/Forgotten Items',
        content: `Guests are advised to monitor their luggage while at our terminals and onboard our vehicles as GIGM will not be liable for lost, forgotten or stolen luggage.\nFound but unclaimed items will be kept for a maximum of 30 days before it is disposed of. Perishable items however, will be discarded the same day if unclaimed, to prevent contamination.`
      },
      {
        id:17,
        title: 'In Transit Breakdown',
        content: `As a company, we recognise the importance of preventive maintenance and we practice it thoroughly to reduce and possibly eliminate in-transit breakdowns. However, in the event that it does happen, guests are advised to contact us via our WhatsApp Bot.\nFor swift action, our rapid response unit are also strategically located to provide a replacement vehicle within the shortest possible time. In situations where an alternative vehicle is provided by GIGM to complete the trip, please note that tickets cannot be reused.`
      },
]