export const pickupData = [
  {
    id: 1,
    state: "abia",
    terminal: "Aba",
    location: [
      "Leno petroleum Nig. Ltd, along Aba - Port Harcourt ExpressWay Aba.",
      "Nigerian Navy Finance and Logistics College, Owerrinta",
      "Royal Cruise Restaurant By Isiala Ngwa Junction.",
      "Royal Supermarket. 91, Aba-Owerri Road, Aba."
    ],
  },
  {
    id: 2,
    state: "Abia",
    terminal: "Umuahia",
    location: [
      "Hoeffers fast food, Akanu Ibiam Road, by Government House Road.FACFOG PLAZA  East/West Road Choba",
      "Sabuga filling station,Okigwe by Enugu Express",
      "3cHub by Umuahia Town."
    ],
  },
  {
    id: 3,
    state: "Lagos",
    terminal: "Ajah",
    location: [
      "Lekki 1 Admiralty Way by First Bank",
      "Jakande Mobil Filling Station",
      "Ikota Mega Chicken",
      "Ziuss filling station, Ibeju Lekki",
      "Sango Tedo Nero Bus stop",
      "Lakowe Total Filling Station",
      "Bunvic Invest. Filling station by Epe junction",
      "Ikate Bus stop by traffic light",
      "New road junction, after Igboefon traffic light by Eti-osa local Government secretariat",
      "Pinnacle Filling station by 2nd roundabout, Marwa",
      "Fowobi Filling Station Ijebu Itele along Shagamu-Benin expressway, Ijebu ode",
      "Oando Filling Station, Chisco Bus-Stop(Former 4th Roundabout) Along Lekki-Epe Expressway, Lekki",
      "Total Filling Station, Eleko Beach Junction, Along Lekki-Epe Expressway, Ibeju Lekki",
      "Front of Mayfair garden estate opposite debase oil and gas filling station, Ibeju Lekki."
    ],
  },
  {
    id: 4,
    state: "Lagos",
    terminal: "Lekki",
    location: [
  //     "Lekki 1 Admiralty Way by First Bank",
  //     "Jakande Mobil Filling Station",
  //     "Ikota Mega Chicken",
  //     "Ziuss filling station, Ibeju Lekki",
  //     "Sango Tedo Nero Bus stop",
  //     "Lakowe Total Filling Station",
  //     "Bunvic Invest. Filling station by Epe junction",
  //     "Ikate Bus stop by traffic light",
  //     "New road junction, after Igboefon traffic light by Eti-osa local Government secretariat",
  //     "Pinnacle Filling station by 2nd roundabout, Marwa",
  //     "Fowobi Filling Station Ijebu Itele along Shagamu-Benin expressway, Ijebu ode",
  //     "Oando Filling Station, Chisco Bus-Stop(Former 4th Roundabout) Along Lekki-Epe Expressway, Lekki",
  //     "Total Filling Station, Eleko Beach Junction, Along Lekki-Epe Expressway, Ibeju Lekki",
  //     "Front of Mayfair garden estate opposite debase oil and gas filling station, Ibeju Lekki."
    ],
  },
  {
    id: 5,
    state: "Lagos",
    terminal: "Akowonjo",
    location: [
      " Sterling Bank by Isheri Bus-stop,Isheri-Idimu road",
      "Forte oil by car wash bus-stop Isheri-Egbeda road",
      "O.O Petroleum, Ile-Epo Alhaji Bus-stop",
      " First royal petroleum 40 Akowonjo road Egbeda",
      "Zone 4 filling station Egbeda Bus stop",
      "Vulcanizer Bus-stop Akowonjo Road",
      "Oando filling station salami bus-stop shasha road",
      "Dapsey filling station opposite Omolore street Moshalashi bus-stop shasha",
      "Mobil filling station Oremeta bus-stop shasha"
    ],
  },
  {
    id: 6,
    state: "lagos",
    terminal: "cele-okota",
    location: [
      " Mobil Filling Station Gbagada",
      "Mobil Filling Station Magodo",
      "Oando Filling Station Berger"
    ],
  },
  {
    id: 7,
    state: "lagos",
    terminal: "festac",
    location: [
      "NNPC filling Station by Rainbow along Mile2 express road",
      "Mega Chicken by Apple Junction."
    ],
  },
  {
    id: 8,
    state: "lagos",
    terminal: "mazamaza",
    location: [
      // "NNPC filling Station by Rainbow along Mile2 express road",
      // "Mega Chicken by Apple Junction."
    ],
  },
  {
    id: 9,
    state: "lagos",
    terminal: "iyana ipaja",
    location: [
      "Mobil Filling Station Ijaye road by Wemco road Ogba.",
      "Omole Estate (1st Gate)",
      "Berger NGAB Filling station",
      "Mowe NNPC Filling station",
      "otal Filling Station, along Sagamu-Benin expressway, Sagamu."
    ],
  },
  {
    id: 10,
    state: "lagos",
    terminal: "Ikotun",
    location: [
      "Conoil filling station, Igando",
      "Odo Eran Junction, Lasu-Isheri Road",
      "BRT bus station, Council bus stop, Ikotun-idimu road."
    ],
  },
  {
    id: 11,
    state: "lagos",
    terminal: "Jibowu",
    location: [
      "Maryland Oando Filling Station",
      "Magodo Mobil Filling Station",
      "Berger Oando Mowe",
      "Mowe NNPC Filling Station by Lotto Bus stop.",
      "Total Filling Station, along Sagamu-Benin expressway, Sagamu"
    ],
  },
  {
    id: 12,
    state: "Anambra",
    terminal: "Onitsha",
    location: [
      "Holiday Resort Hotel, Onitsha",
      "Anioma Filling Station, Traffic Light Off Express Rd by Ezenneyi Junction",
      "Iluobe Filling Station, Issele Uku Junction. Delta State",
      "Rainoil Filling Station, Summit Junction Along Asaba- Benin Expressway",
      "Railoil Filling Station, Coker Junction Asaba.",
      "Tony chuks filling station, Umunede Junction.",
      "Ziuss filling station, Along Asaba-Benin expressway after old immigration office.",
      "Hensmor Nig. Ltd Uromi Junction, Benin/Asaba Expressway, Agbor.",
      "Laga Petroleum and Gas Ltd Agbor Junction, along Agbor-Benin Expressway.",
      "Ifedico Oil and Gas Station, Onitsha Ugbo Junction along Onitcha Ugbo - Benin Expressway."
    ],
  },
  {
    id: 13,
    state: "Delta",
    terminal: "Asaba",
    location: [
      // "Holiday Resort Hotel, Onitsha",
      // "Anioma Filling Station, Traffic Light Off Express Rd by Ezenneyi Junction",
      // "Iluobe Filling Station, Issele Uku Junction. Delta State",
      // "Rainoil Filling Station, Summit Junction Along Asaba- Benin Expressway",
      // "Railoil Filling Station, Coker Junction Asaba.",
      // "Tony chuks filling station, Umunede Junction.",
      // "Ziuss filling station, Along Asaba-Benin expressway after old immigration office.",
      // "Hensmor Nig. Ltd Uromi Junction, Benin/Asaba Expressway, Agbor.",
      // "Laga Petroleum and Gas Ltd Agbor Junction, along Agbor-Benin Expressway.",
      // "Ifedico Oil and Gas Station, Onitsha Ugbo Junction along Onitcha Ugbo - Benin Expressway."
    ],
  },
  {
    id: 14,
    state: "Delta",
    terminal: "Warri",
    location: [
      "Sapele-Amukpe roundabout, Sapele.",
    ],
  },
  {
    id: 15,
    state: "Benin",
    terminal: "Auchi",
    location: [
      "Ekpoma Terminal,Ekpoma",
      "Elizac Fast Food,Irrua",
      "Paragon Filling Station,Okpella",
      "Old Danco Filling Station, Benin-Auchi Road.",
      " NNPC Filling Station, Opposite Irrua Specialist Teaching Hospital, Irrua.",
      "Jettu Junction, Along Auchi-Okene Expressway.",
      " Iluobe Filling Station, Agbede."
    ],
  },
  {
    id: 16,
    state: "Benin",
    terminal: "Akpakpava",
    location: [
      "Otopec Filling Station ITV Glass House Airport",
      "Road Forte Oil Sapele Road by Hallmark",
      "Danco Filling Station by Mat-Ice, Ekehuan Road"
    ],
  },
  {
    id: 17,
    state: "Benin",
    terminal: "Uselu",
    location: [
      // "Otopec Filling Station ITV Glass House Airport Road",
      // "Forte Road Forte Oil Sapele Road by Hallmark",
      // "Danco Filling Station by Mat-Ice, Ekehuan Road",
      "Showboy Filling Station opposite Okada Town Junction, along Benin-Lagos ExpresswayMowe NNPC Filling station",
      "Zius Oil New Lagos Road",
      "Forte Oil, Isihor",
      "Total Filling Station, New Benin",
      "Uniben main gate by the pedestrian bridge"
    ],
  },
  {
    id: 18,
    state: "Benin",
    terminal: "Ekpoma",
    location: [
      "Iruekpen (Total Filling Station)",
      "Irrua (Elizac Fast Food)"
    ],
  },
  {
    id: 19,
    state: "Bayelsa",
    terminal: "Bayelsa",
    location: [
      "Kimiowei Oil Ltd Kaiama",
      "Tony Greenfield Filling Station before Igbogene junction"
    ],
  },
  {
    id: 20,
    state: "Bayelsa",
    terminal: "Yenagoa",
    location: [
      "Tony Greenfield Filling Station before Igbogene junction"
    ],
  },
  {
    id: 21,
    state: "Enugu",
    terminal: "Enugu",
    location: [
      "NNPC Filling Station, Nike Lake Road Abakpanike.",
      "Chili's Food Limited. 2a/2b Nike Lake Road. Nowas Transekulu",
      "Under the fly over, Enugu night mile."
    ],
  },
  {
    id: 22,
    state: "Oyo",
    terminal: "Ibadan",
    location: [
      "Total filling station, by roundabout Iwo road, New Ife road, Ibadan",
      "Amala spot, opp Mobil filling station, after Ibadan Toll gate, along Lagos-Ibadan expressway"
    ],
  },
  {
    id: 23,
    state: "Imo",
    terminal: "Owerri",
    location: [
      "Imo State Library bus stop",
      "NNPC by Onitsha Road",
      "Coisco Nig Ltd Obgaku",
      "Fountain Rock filling station Mgbidi",
      "OKIKE Oil Ltd Mgbidi",
      "Frank Girly Filling Station opp Emmanuel college by Aba road",
      "Total filling station opp First Bank, Ihiala",
      "Uli centre, opposite Uli university Junction",
      "Master Energy Filling Station, No 1 Naze by Federal Polytechnic, Nekede",
      "Virgin Forest Energy by Amawire, Orji",
      "IMSU Bus stop by Chukwuma Nwoha Junction(roundabout)"
    ],
  },
  {
    id: 24,
    state: "Rivers",
    terminal: "PortHarcourt",
    location: [
      "YKC Filling Station, by Woji roundabout, Woji.",
      "Kilimanjaro Restaurant Agip road, Agip roundabout, Rumeme.",
      "Perpetual Fast Food, Igwuruta Roundabout, Along Port Harcourt International Airport Road Igwuruta.",
      "Pepperoni Restaurant, Winppy Junction by Ada George road",
      "Total Filling Station opposite CIWA, Portharcourt-Aba road.",
      "Richpon Fuel Station by Rukpokwu roundabout.",
      "Big Treat Bakery Rupokpu Round about."
    ],
  },
  {
    id: 25,
    state: "Abuja",
    terminal: "Utako",
    location: [
      "Arab Junction by Berger Roundabout",
      "Pin Wonyin Junction by MRS Filling Station",
      "University Junction/SDP Junction Gwagwalada",
      "Giri Junction by NNPC Mega Station",
      "Bakka Oil Filling Station Gwagwalada",
      "Conoil Filling Station by River park estate Lugbe",
      "Madayana Filling Station, Abaji",
      "UBA Foods, Along Abuja-Lokoja Expressway, Lokoja",
      "Shafa filling station, Dakwa by Dei-Dei junction. Dei - Dei FCT Abuja",
      "Eyo service station zuba. FCT Abuja",
      "Mainland oil and gas FCDA Kubwa by Dutse junction FCT, Abuja",
    ],
  },
  {
    id: 26,
    state: "Akwaibom",
    terminal: "Uyo",
    location: [
      "Obikason Filling station Ikot Epene RD Itak",
      "Kemson Oil filling station, Ikot Ekpene"
    ],
  },
];
