import React, { useEffect } from "react";
import ellipseBullet from "../../assets/img/circle-bullet.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import "./iso.css";

const IsoComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1
                className="mb-2"
                style={{ maxWidth: "750px", margin: "0 auto" }}
              >
                Integrated Management System Policy Statement
              </h1>
              {/* <p>Personal Information at GIGM.</p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="cardholder-privacy">
                GIGM is committed to maintaining and improving information
                security and  business continuity processes by adopting an
                integrated management system. This provides a  framework for
                integration of the ISO 27001:2022 and ISO 9001:2015 standards.
              </p>

              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  The company defines our core values for Integrated Management
                  System as follows: 
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  To give satisfaction to all our customers, other stakeholders
                  and interested parties, meeting and exceeding their
                  expectations
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  To reduce the number of high priority risks related to
                  information security and quality management.
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  To reduce or avoid information security breaches and related
                  loss.
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  To Ensure Compliance with relevant Contractual, Regulatory,
                  and Legal requirements and avoid sanctions/penalties.  
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  To enforce data protection by ensuring that information
                  collected, held, and used by the organization is in line with
                  NPDR requirements.
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  To provide training in information security and quality
                  management for key resources.
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  Create awareness to all relevant stakeholders on the need for
                  compliance with Information Security &  Quality Management
                  Systems requirements, and the stakeholders’ responsibilities.
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  To ensure continual improvement of our Information Security & 
                  Quality Management System.
                </span>
              </p>
              <p style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={ellipseBullet}
                  width="20"
                  height="20"
                  alt=""
                  className="mr-3"
                />
                <span>
                  The Integrated Management System policy, objectives and
                  targets will be reviewed annually (or  sooner if necessary) by
                  Top Management. This policy statement is communicated to all 
                  employees and persons working for or on behalf of the company
                  and will be made available to  the public, stakeholders, and
                  any other interested parties on request.
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default IsoComponent;
