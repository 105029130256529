import React, { useEffect } from "react";
import AwardLong from "../../assets/img/award-long.png";
import AwardShort from "../../assets/img/award-short.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import "./index.css";

export const AwardsComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section className="pickup">
        <div className="container">
          <div
            className="d-flex flex-column flex-wrap justify-content-between align-items-center"
            style={{ marginTop: "50px", color: "white" }}
          >
            <h3
              style={{
                fontWeight: "600",
                marginBottom: "40px",
                color: "black",
              }}
            >
              Awards
            </h3>
            <div className="awards">
              <h5 className="align-self-start" style={{ color: "black", fontWeight:"700" }}>2011 &#38; 2012</h5>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                {/* <h6>DECEMBER, 2011</h6> */}
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  DECEMBER, 2011
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardShort}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Most Valuable Mobility Company of the year by African Child
                  Foundation.
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  MAY, 2012
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardLong}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Award of Honour for Unparelled Contributions to CASOR by
                  Ambrose Ali University, Ekpoma.
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  JUNE, 2012
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardShort}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Award of Excellence for Commitment to the Development of the
                  Transport Industry by Senior Staff Club, University of Benin,
                  Benin City.
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  AUGUST, 2012
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardLong}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  International service Award for contributions towards the
                  Development and Growth of Delta State by Rotary Club of Asaba
                  East
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  SEPTEMBER, 2012
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardShort}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Transport Company of the Year 2012 by Niger Delta Award.
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  DECEMBER, 2012
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardLong}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Distinguished Gold Service Award for Excellence in Road
                  Transport Management 2012 by Centre For democratic Governance
                  in Africa
                </p>
              </div>
            </div>

            <div className="awards">
              <h5 style={{ color: "black", fontWeight:"700", marginTop:"50px" }}>2013 &#38; 2014</h5>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  JUNE, 2013
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardShort}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Transport Company of the Year, 2013 by Network Oil &#38; Gas
                  (NO &#38; G) Ltd
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  {" "}
                  JUNE 2014
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardLong}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Service Award for Entrepreneurial Contribution to the Society
                  by Nigerian Bar Association, Benin.
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  {" "}
                  AUGUST 2014
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardShort}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Vocational Service Award for Outstanding Contribution and
                  Robust History in the Transport Sector of Nigeria's Economy.
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  {" "}
                  NOVEMBER 2014
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardLong}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Award of Excellence in recognition as the most efficient and
                  consistent road passenger transport company of the year 2014
                  by Institute of Transport Administration Nigeria (IOTA){" "}
                </p>
              </div>
            </div>

            <div className="awards">
              <h5 style={{ color: "black",fontWeight:"700", marginTop:"50px" }}>2015 &#38; 2016</h5>
            </div>
            <div className="d-flex flex-wrap justify-content-center">
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  DECEMBER, 2015
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardShort}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Best Customer Service company in the mobility sector,
                  2015 at the Nigeria Customer Service Awards
                  <br />
                  Best Land Transport Company, 2015 in the African Governance
                  &#38; Corporate Leadership Awards by Institute For Government
                  Research Leadership Technology
                </p>
              </div>
              <div
                className="award col-md-4 col-sm-12 d-flex flex-column align-items-center justify-content-center"
                style={{
                  backgroundColor: "#171F31",
                  borderRadius: "10px",
                  padding: "20px 30px",
                  margin: "30px 20px",
                  flexBasis:"30%"
                  //   width: "70% !important",
                }}
              >
                <h6 className="align-self-start" style={{ fontSize: ".8rem" }}>
                  MAY, 2016
                </h6>
                <div
                  className="award-img"
                  style={{
                    objectFit: "cover",
                    width: "150px",
                    height: "150px",
                  }}
                >
                  <img
                    src={AwardLong}
                    style={{ width: "100%", height: "100%" }}
                    alt="award"
                  />
                </div>
                <p className="text-center" style={{ fontSize: ".9rem" }}>
                  Most Innovative Transport Company in Nigeria by African Center
                  for Supply Chain.
                  <br />
                  Road Transport Company of the year by Transport Day Newspaper
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
export default AwardsComponent;
