import React, { useEffect } from "react";
import Navbar from "../../components/NavBar";
import Footer from "../../components/Footer";
import "./index.css";
import paybills from "../../assets/img/paybills.png";
import security from "../../assets/img/security.png";
import reward from "../../assets/img/reward.png";
import Appinstall from "../../components/Appinstall";

const PayBills = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />

      <section className="paybills">
        <div className="container">
          <div className="row row-grid">
            <div className="col-md-6 align-self-center">
              <div className="pay-bills">
                <h1>The easiest way to pay your bills while on the move</h1>
                <br />
                <p>
                  Enjoy the simplicity of the GIGM bills feature and have fun
                  while navigating through our intuitive interface when paying
                  recurring bills. Top-up airtime, buy data, pay for electricity
                  and entertainment when and where you want to in three easy
                  steps.
                </p>
                <br />
                <p class="bills-list">
                  <span class="bullet"></span>Fund e-wallet via debit card or
                  bank transfer
                </p>
                <br />
                <p class="bills-list">
                  <span class="bullet"></span>Select desired service on the
                  bills payment listing
                </p>
                <br />
                <p class="bills-list">
                  <span class="bullet"></span>Input required details and hit the
                  pay option right from your e-wallet{" "}
                </p>
                <div className="bill-cards">
              <div className="security">
                <div className="">
                  <img src={security} width="70" height="70" alt="Security" />
                </div>
                <br />
                <h4><b>Security</b></h4>
                <br />

                <p>
                  When it comes to the storage or exchange of money for value,
                  security matters. Our unique transaction pin authentication
                  ensures that the money in your wallet is safe and completely
                  protected from fraud.
                </p>
              </div>
              <div className="rewards">
                <div className="">
                  <img src={reward} width="70" height="70" alt="reward" />
                </div>
                <br />
                <h4><b>Rewards</b></h4>
                <br />
                <p>
                  N.B Enjoy a 5% cashback when you pay with the GIGM e-wallet
                </p>
              </div>
            
            </div>

                {/* <p>
                  Wallet users can now fund their wallet via Paystack and Woven
                  (Bank Transfer) and this funded account is visible on the
                  Customers Dashboard where they can view their Wallet Balance
                  in Naira. This feature will enable customers to book
                  seamlessly on the Mobile App and Booking via Wallet comes with
                  a <span style={{ fontWeight: "600" }}>5% cashback.</span>
                </p> */}
                <br />
                {/* <p>
                  <span style={{ fontWeight: "bold" }}>Wallet 2FA:</span> The
                  Product Security and Safety for the GIGM Mobile App is apt and
                  we have ensured that every single wallet transaction comes
                  with a Transaction PIN to authorize transactions. These PINs
                  can be changed anytime.
                </p> */}
              </div>
            </div>
            <div className="col-md-6 col-sm-12 pl-5">
              <div className="paybills-imgholder">
                <div className="">
                  <img src={paybills} alt="paybills" className="img-fluid" />
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <Appinstall />
      <Footer />
    </div>
  );
};

export default PayBills;
