import React, { useEffect } from "react";
import shot4 from "../../assets/img/shot1.jpg";
import shot3 from "../../assets/img/shot2.jpg";
import shot2 from "../../assets/img/shot3.jpg";
import shot1 from "../../assets/img/shot4.jpg";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import "../privacy/privacy.css";

const DataCollection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="mb-2">Data Collection</h1>
              {/* <p>Personal Information at GIGM.</p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="cardholder-privacy">
                Steps a user would take to delete their account.
              </p>
              <p>1. User authenticate using their username and password</p>
              <p>2. User navigates to their profile</p>
              <p>3. User taps on “delete account”</p>
              <p>
                4. User gets a confirmation pop message to be sure they want to
                go ahead with the delete process
              </p>
              <p>Once user confirms, the account would be deleted</p>
              <p>Data kept and retention period</p>
              <p>
                When a user’s account is deleted, the data would be made
                anonymous. We keep users trip and bills payment transactions
                records. We keep the anonymous data for 5 years before
                permanently deleting it from our database.
              </p>

              <p><b>See Screenshots below:</b></p>
              <div className="data-cards" style={{display: "flex", flexWrap: "wrap"}}>
              <div
                  className="mr-5"
                  style={{
                    width: "240px",
                    height: "540px",
                    objectFit: "cover",
                    boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                    // borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={shot1}
                    alt="sceenshot-1"
                    className=""
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="mr-5"
                  style={{
                    width: "240px",
                    height: "540px",
                    objectFit: "cover",
                    boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                    // borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={shot2}
                    alt="sceenshot-2"
                    className=""
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="mr-5"
                  style={{
                    width: "240px",
                    height: "540px",
                    objectFit: "cover",
                    boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                    // borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={shot3}
                    alt="sceenshot-3"
                    className=""
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                </div>
                <div
                  className="mr-5"
                  style={{
                    width: "240px",
                    height: "540px",
                    objectFit: "cover",
                    boxShadow: "0 0 10px rgb(24 51 51 / 20%)",
                    // borderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={shot4}
                    alt="sceenshot-4"
                    className=""
                    loading="lazy"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default DataCollection;
