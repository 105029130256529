export const terminalData = [
  {
    "id": 1,
    "state":"abia",
    "terminal": "Aba Terminal",
    "logo": require("../../assets/img/terminals/aba-terminal.png").default,
    "address": "No 5 Asa Road, Aba.",
    "phoneNumber": "08139851110",
    "email": "aba@gigm.com"
  },
  {
    "id": 2,
    "state":"abia",
    "terminal": "Umuahia Terminal",
    "logo": require("../../assets/img/terminals/umuahia-terminal.png").default,
    "address": "NO. 8 Mission Hill Before Okpara Square, Umuahia, Abia State.",
    "phoneNumber": "08139851110",
    "email": "umuahia@gigm.com"
  },
  {
    "id": 3,
    "state":"abuja",
    "terminal": "Maraba Terminal",
    "logo": require("../../assets/img/terminals/maraba-terminal.png").default,
    "address": "132 Giza Plaza, Opp ChrisGold Plaza. Abacha Road.",
    "phoneNumber": "08139851110",
    "email": "maraba@gigm.com"
  },
  {
    "id": 4,
    "state":"abuja",
    "terminal": "Zuba Terminal",
    "logo": require("../../assets/img/terminals/zuba-terminal.png").default,
    "address": "Plot 9 Kaduna/Lokoja expressway close to Enyo filling station Zuba. ",
    "phoneNumber": "08139851110",
    "email": "madalla@gigm.com"
  },
  {
    "id": 5,
    "state":"abuja",
    "terminal": "Madalla-zuba Terminal",
    "logo": require("../../assets/img/terminals/madalla-terminal.png").default,
    "address": "GIGMobility, Along Abuja-Kaduna Express Way By Mobil Filling Station",
    "phoneNumber": "08139851110",
    "email": "madalla@gigm.com"
  },
  {
    "id": 6,
    "state":"abuja",
    "terminal": "Utako Terminal",
    "logo": require("../../assets/img/terminals/utako-terminal.png").default,
    "address": "Plot 113, I.V.W Osisidgwu Crescent, beside Utako Police Station, Utako District, FCT, Abuja",
    "phoneNumber": "08139851110",
    "email": "utako@gigm.com"
  },
  {
    "id": 7,
    "state":"abuja",
    "terminal": "Kubwa Terminal",
    "logo": require("../../assets/img/terminals/kubwa-terminal.png").default,
    "address": "Kukuwaba General Park, close to Ecqa Church",
    "phoneNumber": "08139851110",
    "email": "kubwa@gigm.com"
  },
  {
    "id": 8,
    "state":"akwaibom",
    "terminal": "Uyo Terminal",
    "logo": require("../../assets/img/terminals/uyo-terminal.png").default,
    "address": "No. 3 Mosignor Akpan Avenue, Opposit Itam Timber Market, Uyo",
    "phoneNumber": "08139851110",
    "email": "uyo@gigm.com"
  },
  {
    "id": 9,
    "state":"anambra",
    "terminal": "Awka Terminal",
    "logo": require("../../assets/img/terminals/awka-terminal.png").default,
    "address": "Elite Shopping Complex, Along Enugu - Onitsha Expressway, Unizik Temp. Site, Awka.",
    "phoneNumber": "08139851110",
    "email": "awka@gigm.com"
  },
  {
    "id": 10,
    "state":"bayelsa",
    "terminal": "Bayelsa Terminal",
    "logo": require("../../assets/img/terminals/bayelsa-terminal.png").default,
    "address": "Kpansia Opposite Wema Bank By INEC Junction, Yenagoa, Bayelsa State",
    "phoneNumber": "08139851110",
    "email": "yenegoa@gigm.com"
  },
  {
    "id": 11,
    "state":"edo",
    "terminal": "Auchi Terminal",
    "logo": require("../../assets/img/terminals/auchi-terminal.png").default,
    "address": "Along Auchi Okene Express Road Opp Sport Complex Auchi",
    "phoneNumber": "08139851110",
    "email": "auchi@gigm.com"
  },

  {
    "id": 12,
    "state":"edo",
    "terminal": "Benin-Akpakpava Terminal",
    "logo": require("../../assets/img/terminals/auchi-terminal.png").default,
    "address": "118 Akpakpava road. Benin city",
    "phoneNumber": "08139851110",
    "email": "akpakpava@gigm.com"
  },
  {
    "id": 13,
    "state":"edo",
    "terminal": "Benin Uselu Terminal",
    "logo": require("../../assets/img/terminals/auchi-terminal.png").default,
    "address": "202 Uselu Lagos Road, Ugbowo Benin City, Edo State",
    "phoneNumber": "08139851110",
    "email": "uselu@gigm.com"
  },
  {
    "id": 14,
    "state":"edo",
    "terminal": "Ekpoma Terminal",
    "logo": require("../../assets/img/terminals/auchi-terminal.png").default,
    "address": "55 Benin-Auchi Road Opposite Gray Center, Ekpoma",
    "phoneNumber": "08139851110",
    "email": "ekpoma@gigm.com"
  },
  {
    "id": 15,
    "state":"delta",
    "terminal": "Asaba Terminal",
    "logo": require("../../assets/img/terminals/asaba-terminal.png").default,
    "address": "Asaba-Onitsha Expressway, By Head Bridge",
    "phoneNumber": "08139851110",
    "email": "asaba@gigm.com"
  },
  {
    "id": 16,
    "state":"delta",
    "terminal": "Warri Terminal",
    "logo": require("../../assets/img/terminals/warri-terminal.png").default,
    "address": "116 Effurun Sapele Road, Opposite Our Lady's, Warri Delta State",
    "phoneNumber": "08139851110",
    "email": "warri@gigm.com"
  },
  {
    "id": 17,
    "state":"enugu",
    "terminal": "Enugu Terminal",
    "logo": require("../../assets/img/terminals/enugu-terminal.png").default,
    "address": "No 1 Zek's Avenue along holy ghost round opposite Osisa tech. Poly by P&T Bus Stop Enugu State.",
    "phoneNumber": "08139851110",
    "email": "enugu@gigm.com"
  },
  {
    "id": 18,
    "state":"enugu",
    "terminal": "Nsukka Terminal",
    "logo": require("../../assets/img/terminals/enugu-terminal.png").default,
    "address": "64 Owerre-Ani, Enugu Road, Nsukka Enugu State",
    "phoneNumber": "08139851110",
    "email": "nsukka@gigm.com"
  },
  {
    "id": 19,
    "state":"imo",
    "terminal": "Owerri Terminal",
    "logo": require("../../assets/img/terminals/owerri-terminal.png").default,
    "address": "Plot 31C Relief Market Road Off Egbu Road",
    "phoneNumber": "08139851110",
    "email": "owerri@gigm.com"
  },
    {
      "id": 20,
      "state":"lagos",
      "terminal": "Jibowu Terminal",
      "logo": require("../../assets/img/terminals/jibowu-terminal.png").default,
      "address": "20 Ikorodu Express Road,Jibowu,Lagos",
      "phoneNumber": "08139851110",
      "email": "jibowu@gigm.com"
    },
    {
        "id": 21,
        "state":"lagos",
        "terminal": "Iyana-Ipaja Terminal",
        "logo": require("../../assets/img/terminals/iyana-ipaja-terminal.png").default,
        "address": "164 Lagos/Abokuta Express way Iyana Ipaja Lagos",
        "phoneNumber": "08139851110",
        "email": "iyana-ipaja@gigm.com"
      },
      {
        "id": 22,
        "state":"lagos",
        "terminal": "Cele Terminal",
        "logo": require("../../assets/img/terminals/cele-terminal.png").default,
        "address": "103 Okota Road Cele, Lagos",
        "phoneNumber": "08139851110",
        "email": "celeo@gigm.com"
      },
      {
        "id": 23,
        "state":"lagos",
        "terminal": "Festac Terminal",
        "logo": require("../../assets/img/terminals/festac-terminal.png").default,
        "address": "First Gate Opposite 2nd Avenue Festac Town, Lagos",
        "phoneNumber": "08139851110",
        "email": "festac@gigm.com"
      },

      {
        "id": 24,
        "state":"lagos",
        "terminal": "Old-Ojo Terminal",
        "logo": require("../../assets/img/terminals/old-ojo-terminal.png").default,
        "address": "112, Old-Ojo Road by Demorose Busstop, Lagos",
        "phoneNumber": "08139851110",
        "email": "oldojo@gigm.com"
      },
      {
        "id": 25,
        "state":"lagos",
        "terminal": "Ikotun Terminal",
        "logo": require("../../assets/img/terminals/ikotun-terminal.png").default,
        "address": "GIGM Terminal, 29 Ikotun-Idimu Road, Ikotun Lagos",
        "phoneNumber": "08139851110",
        "email": "ikotun@gigm.com"
      },
      {
        "id": 26,
        "state":"lagos",
        "terminal": "Yaba Terminal",
        "logo": require("../../assets/img/terminals/yaba-terminal.png").default,
        "address": "Yaba Tejuosho Shopping Complex, Phase 1. Lagos",
        "phoneNumber": "08139851110",
        "email": "yaba@gigm.com"
      },
      {
        "id": 27,
        "state":"lagos",
        "terminal": "Akowonjo Terminal",
        "logo": require("../../assets/img/terminals/akowonjo-terminal.png").default,
        "address": "39/41 Shasha Road, Dopemu Lagos",
        "phoneNumber": "08139851110",
        "email": "akowonjo@gigm.com"
      },
      {
        "id": 28,
        "state":"lagos",
        "terminal": "Volkswagen Terminal",
        "logo": require("../../assets/img/terminals/volkswagen-terminal.png").default,
        "address": "Ilaje Bus Stop., Badagry Expressway, Lagos",
        "phoneNumber": "08139851110",
        "email": "volkswagen@gigm.com"
      },
      {
        "id": 29,
        "state":"lagos",
        "terminal": "Ajah Terminal",
        "logo": require("../../assets/img/terminals/ajah-terminal.png").default,
        "address": "GIGM Terminal close to Ajiwe Police Station, Ajah-Epe express way, Ajah , Lagos",
        "phoneNumber": "08139851110",
        "email": "ajah@gigm.com"
      },
      // {
      //   "id": 30,
      //   "state":"lagos",
      //   "terminal": "Lekki Pick Up/ Booking Center",
      //   "logo": require("../../assets/img/terminals/ajah-terminal.png").default,
      //   "address": "No 1, Wole Ariyo street off Admiralty way beside first bank.",
      //   "phoneNumber": "08139851110",
      //   "email": "lekkibookingcenter@gigm.com"
      // },
      {
        "id": 30,
        "state":"cross-river",
        "terminal": "Calabar Terminal",
        "logo": require("../../assets/img/terminals/kubwa-terminal.png").default,
        "address": "74, Etta Agbo Road, University of Calabar, Calabar, Cross River.",
        "phoneNumber": "08139851110",
        "email": "crossriver@gigm.com"
      },
      {
        "id": 31,
        "state":"kaduna",
        "terminal": "Kaduna Terminal",
        "logo": require("../../assets/img/terminals/kaduna-terminal.png").default,
        "address": "Nnamdi Azikiwe Bypass beside Bible Society Street, Before Command Junction Romi New Extension, Kaduna.",
        "phoneNumber": "08139851110",
        "email": "kaduna@gigm.com"
      },
      // {
      //   "id": 32,
      //   "state":"kaduna",
      //   "terminal": "Kaduna-South Terminal",
      //   "logo": require("../../assets/img/terminals/kaduna-terminal2.png").default,
      //   "address": "Nnamdi Azikwe Bye Pass, Gracious Motor Bus Stop, Ungwan Romi New Extension, Kaduna South,",
      //   "phoneNumber": "08139851110",
      //   "email": "kaduna@gigm.com"
      // },
      {
        "id": 33,
        "state":"portharcourt",
        "terminal": "Portharcourt Terminal",
        "logo": require("../../assets/img/terminals/port-terminal.png").default,
        "address": "Genesis junction, off Aba road by Cocaine estate, Rumuagba, Port Harcourt, Rivers State.",
        "phoneNumber": "08139851110",
        "email": "portharcourt@gigm.com"
      },
      {
        "id": 34,
        "state":"plateau",
        "terminal": "Jos Terminal",
        "logo": require("../../assets/img/terminals/jos-terminal.png").default,
        "address": "Angwan Soya, Zaria Bye-Pass, Opposite Bingham Teaching Hospital, Jos.",
        "phoneNumber": "08139851110",
        "email": "jos@gigm.com"
      },
      {
        "id": 4,
        "state":"abuja",
        "terminal": "Zuba Terminal",
        "logo": require("../../assets/img/terminals/zuba-terminal.png").default,
        "address": "Plot 9 Kaduna/Lokoja expressway close to Enyo filling station Zuba",
        "phoneNumber": "08139851110",
        "email": "madalla@gigm.com"
      },
      
  ]

