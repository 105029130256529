import React from "react";
import "./note.css";

const PleaseNote = () => {
  return (
    <div>
      <section className="note">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <h1 className="mb-2">Please note the following</h1>
              <p>
                Unclaimed properties are typically kept for 30 days before they
                are disposed of (except for perishable items which are discarded
                by the next day to prevent contamination). If a perishable item
                becomes a health risk, we would dispose of such items
                immediately for safety reasons. Should you need to contact us to
                reclaim your forgotten property, please reach us via {" "}{" "}<a
                        target="_blank"
                        rel="noreferrer"
                        href="http://wa.me/+2349087616915"
                      >
                        <i class="fa fa-whatsapp" aria-hidden="true" style={{color:"green", fontWeight:"bold", fontSize:"1.2rem"}}></i>{" "}
                      </a>,
                contact@gigm.com or 08139851110.
              </p>
              <br />
              {/* <p>
                Kindly reach us via phone on 08139851110 or email
                contact@gigm.com for more details. Please note that this may be
                subject to change, in accordance with modifications in our
                policies. For re claim of lost or forgotten property, you will
                be required to satisfy us that the item belongs to you
              </p> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PleaseNote;
