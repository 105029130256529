import React, { useEffect } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/NavBar";
import "./privacy.css";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="mb-2">Privacy Policy</h1>
              {/* <p>Personal Information at GIGM.</p> */}
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-text">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p className="cardholder-privacy">
                Cardholder privacy is important to GIG Mobility (GIGM). To
                better protect the privacy of cardholder data, GIGM provides
                this privacy statement explaining the security and handling
                practices of cardholder data in the processing of payment card
                transactions.
              </p>
              <p>
                This privacy statement applies to all cardholder data collected
                by or submitted to GIGM’s website. Our website will only collect
                personally identifiable information and cardholder data
                voluntarily provided by cardholders and customers, and will only
                use that information for the specific purposes for which it was
                provided. GIGM will keep this information strictly confidential,
                and will not disclose, sell, or lease the information to third
                parties unless required by law, or with the written permission
                of the cardholder.
              </p>
              <p>
                As with most websites used for payment card transaction
                services, our web servers collect web session data used to
                analyze site trends and gather broad demographic data. GIGM
                reserves the right to collect certain technical information of
                customers such as operating system, IP address, web browser
                software, and URL data through the use of cookies or other
                technology not linked to personally identifiable information or
                cardholder data.
              </p>
              <p>
                GIGM-maintained websites may have links to other third-party
                sites used for payment card transactions. These third-party
                sites may collect cardholder data and personally identifiable
                information through the use of forms or cookies, or from the
                customer's web browser. Cardholders and customers are strongly
                encouraged to review the privacy policies of all third-party
                websites outside the control of GIGM for their procedures for
                collecting, utilizing, and disclosing cardholder data.
              </p>
              <p>
                We have made significant investment in security measures
                employed to protect cardholder data under our control. Access to
                acquired cardholder data and personally identifiable information
                is limited to only those personnel for whom there is an
                established business need to access that data.
              </p>
              <p>
                By using our services on our website or mobile application, you
                consent that your personal information can be used for the above
                purposes.
              </p>
              <p>
                We shall retain your information as long as it is necessary to
                provide the services requested by you and others, subject to any
                legal obligations to further retain such information.
                Information associated with your account will generally be kept
                until it is no longer necessary to provide the services or until
                you ask us to delete it or your account is deleted whichever
                comes first. Additionally, we may retain information from
                deleted accounts to comply with the law, prevent fraud, resolve
                disputes, troubleshoot problems, assist with investigations,
                enforce the Terms of Use, and take other actions permitted by
                law. The information we retain will be handled in accordance
                with this Privacy Policy. Finally, your data could also be
                stored for sales statistical purposes.
              </p>
              <p>
                For questions, comments, or concerns regarding this privacy
                statement, or GIGM’s procedures for securely processing,
                storing, or transmitting cardholder data, please contact us via
                email at{" "}
                <span style={{ color: "#56CCF2" }}> contact@gigm.com.</span>{" "}
                GIGM reserves the right to amend this privacy statement as and
                when required.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Privacy;
