export const pickupDataAjah = [
    {
      id: 1,
      state: "Lagos",
      terminal: "Ajah",
      location: [
        // "Lekki 1 Admiralty Way by First Bank",
        // "Jakande Mobil Filling Station",
        // "Ikota Mega Chicken",
        // "Ziuss filling station, Ibeju Lekki",
        // "Sango Tedo Nero Bus stop",
        // "Lakowe Total Filling Station",
        // "Bunvic Invest. Filling station by Epe junction",
        // "Ikate Bus stop by traffic light",
        // "New road junction, after Igboefon traffic light by Eti-osa local Government secretariat",
        // "Pinnacle Filling station by 2nd roundabout, Marwa",
        // "Fowobi Filling Station Ijebu Itele along Shagamu-Benin expressway, Ijebu ode",
        // "Oando Filling Station, Chisco Bus-Stop(Former 4th Roundabout) Along Lekki-Epe Expressway, Lekki",
        // "Total Filling Station, Eleko Beach Junction, Along Lekki-Epe Expressway, Ibeju Lekki",
        // "Front of Mayfair garden estate opposite debase oil and gas filling station, Ibeju Lekki.",
      ],
    },

  ];
  